import logo from '../logo.svg';
import Button from "@mui/material/Button";
import {LinearProgress} from "@mui/material";
import Grid from '@mui/material/Grid';
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Page1 from "./Survey/Page1";
import Page2 from "./Survey/Page2";
import Page3 from "./Survey/Page3";
import Boolean from "./Survey/Boolean";
import Rating from "./Survey/Rating";
import Textarea from "./Survey/Textarea";
import {useLocation} from "react-router";
import axios from "axios";
import apiConfig from "../util/apiConfig";

function App() {
  const navigate = useNavigate();

  const { state } = useLocation();

  const steps = [
    {
      type: "boolean",
      title: "Is this your first visit to Pandora?",
      subtitle: "Apakah ini kunjungan pertama anda?",
      image: "1. First Visit.png",
      dataKey: "firstExperience"
    },
    {
      type: "rating",
      title: "How was the experience overall?",
      subtitle: "Bagaimana pengalaman bermain anda?",
      image: "2. Experience.png",
      scale: {
        low: "Poor",
        high: "Immersive"
      },
      dataKey: "overallExperience"
    },
    {
      type: "rating",
      title: "How was the in-game properties?",
      subtitle: "Bagaimana kondisi properti dalam permainan?",
      image: "4. Property.png",
      scale: {
        low: "Poor",
        high: "Good"
      },
      dataKey: "gameProperties"
    },
    {
      type: "rating",
      title: "How clear was the pre-game briefing?",
      subtitle: "Bagaimana instruksi sebelum bermain?",
      image: "3. Instruction.png",
      scale: {
        low: "Confusing",
        high: "Clear"
      },
      dataKey: "gameInstruction"
    },
    {
      type: "rating",
      title: "How helpful was our game-master?",
      subtitle: "Bagaimana sikap staff kami?",
      image: "5. Game Master.png",
      scale: {
        low: "Rude",
        high: "Friendly"
      },
      dataKey: "staffBehavior"
    },
    {
      type: "rating",
      title: "How clean was the room?",
      subtitle: "Bagaimana kebersihan ruangan kami?",
      image: "6. Cleanliness.png",
      scale: {
        low: "Dirty",
        high: "Clean"
      },
      dataKey: "roomTidiness"
    },
    state.isBranchImpressive && {
      type: "rating",
      title: "How impressive is the performance of our actors?",
      subtitle: "Seberapa mengesankan penampilan aktor kami?",
      image: "Actor Performance.png",
      scale: {
        low: "Not Impressed",
        high: "Impressed"
      },
      dataKey: "impressive"
    },
    {
      type: "textarea",
      title: "Is there anything we can improve for you?",
      subtitle: "Apakah ada memiliki kritik dan saran?",
      image: "7. Feedback.png",
      dataKey: "comments"
    },
  ].filter(v => v)

  const [ step, setStep ] = useState(0)
  const [ data, setData ] = useState({
    firstExperience: null,
    overallExperience: null,
    gameProperties: null,
    gameInstruction: null,
    staffBehavior: null,
    roomTidiness: null,
    impressive: null,
    comments: ""
  })

  console.log(data, state)

  const stepComponent = steps[step]
  const stepsProps = {
    ...steps[step],
    data,
    setData,
    nextStep: () => setStep(step + 1),
    prevStep: () => setStep(step - 1)
  }

  return (
    <div style={{ padding: "20px", fontFamily: "Montserrat !important" }} >
      <Grid container sx={{ width: '100%', paddingTop: "20px", }} direction={"column"}>
        <Grid item xs={"auto"}>
          <LinearProgress variant="determinate" value={(step + 1) / steps.length * 100} />
        </Grid>
        <Grid item xs style={{ padding: "20px 0px"}}>
          {
            stepComponent.type === "boolean" ?
              <Boolean { ...stepsProps } /> :
              stepComponent.type === "rating" ?
                <Rating { ...stepsProps } /> :
                <Textarea { ...stepsProps } />
          }
        </Grid>
      </Grid>

      <Grid container sx={{ position: "absolute", bottom: "20px", width: "calc(100% - 40px)" }}>
        <Grid item xs={"auto"}>
          <Button
            variant="outlined"
            style={{ padding: "15px 40px", fontFamily: "Montserrat", }}
            disabled={step === 0}
            onClick={() => setStep(step - 1)}
          >
            PREV
          </Button>
        </Grid>
        <Grid item xs />
        {
          step + 1 === steps.length ?
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                style={{ padding: "15px 40px", fontFamily: "Montserrat", }}
                onClick={() => {
                  axios.post(
                    `${apiConfig.base_url}v1/survey`,
                    {
                      ...data,
                      uid: state.id,
                      is_problematic: false,
                      is_completed: false,
                      is_viewed: false
                    }
                  ).then(() => navigate("/success"))
                    .catch(e => console.log(e))
                }}
              >
                SUBMIT
              </Button>
            </Grid> :
            !!data[stepComponent.dataKey] ?
              <Grid item xs={"auto"}>
                <Button
                  variant="contained"
                  style={{ padding: "15px 40px", fontFamily: "Montserrat", }}
                  onClick={() => setStep(step + 1)}
                >
                  NEXT
                </Button>
              </Grid> :
              null
        }
      </Grid>
    </div>
  );
}

export default App;
