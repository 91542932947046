export default {
  title: {
    fontSize: "14px",
    letterSpacing: "0.5px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  subtitle: {
    fontSize: "12px",
    letterSpacing: "0.5px",
    color: "#bbb",
    fontWeight: 400,
    fontFamily: "Montserrat",
  }
}