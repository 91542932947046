import Button from "@mui/material/Button";
import {LinearProgress} from "@mui/material";
import Grid from '@mui/material/Grid';
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import textStyle from "../../util/textStyle";

function App(props) {
  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={props.image}
          className="illustration"
          alt="logo"
          style={{ height: "10vh", margin: "20px 0px 40px 0px" }}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Typography gutterBottom sx={{ ...textStyle.title }}>
          {props.title.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Typography gutterBottom sx={{ ...textStyle.subtitle }}>
          {props.subtitle.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={props.data[props.dataKey] === true ? "contained" : "outlined"}
          style={{ padding: "15px 40px", width: "100%", margin: "20px 0px", fontFamily: "Montserrat" }}
          onClick={() => {
            props.setData({ ...props.data, [props.dataKey]: true })
            props.nextStep()
          }}
        >
          Yes
        </Button>
        <Button
          variant={props.data[props.dataKey] === false ? "contained" : "outlined"}
          style={{ padding: "15px 40px", width: "100%", fontFamily: "Montserrat" }}
          onClick={() => {
            props.setData({ ...props.data, [props.dataKey]: false })
            props.nextStep()
          }}
        >
          No
        </Button>
      </Grid>
    </Grid>
  );
}

export default App;
