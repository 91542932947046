import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red, grey } from '@mui/material/colors';
import Welcome from "./pages/Welcome";
import Survey from "./pages/Survey";
import Success from "./pages/Success";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: red[900],
    },
    secondary: {
      main: grey[900],
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/:id",
    element: <Welcome />,
  },
  {
    path: "/q",
    element: <Survey />,
  },
  {
    path: "/success",
    element: <Success />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
