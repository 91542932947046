import logo from '../logo.svg';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

function App() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px"
      }}
    >
      <img src={"/Logo.png"} className="App-logo" alt="logo" style={{ width: "70%" }} />
      <p
        style={{
          color: "#fff",
          fontSize: "16px",
          fontWeight: 800,
          textAlign: "center",
          letterSpacing: "0.5px",
          marginTop: "50px"
        }}
      >
        THANK YOU FOR YOUR FEEDBACK
      </p>
    </div>
  );
}

export default App;
