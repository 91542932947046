import Button from "@mui/material/Button";
import {FormControlLabel, LinearProgress, RadioGroup} from "@mui/material";
import Grid from '@mui/material/Grid';
import {useNavigate} from "react-router-dom";
import {Component, useState} from "react";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField'
import textStyle from "../../util/textStyle";

function App(props) {
  const navigate = useNavigate();
  const [ isTextareaFilled, setTextareaFilled ] = useState(false)

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={props.image}
          className="illustration"
          alt="logo"
          style={{ height: "10vh", margin: "20px 0px 40px 0px" }}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Typography gutterBottom sx={{ ...textStyle.title }}>
          {props.title.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Typography gutterBottom sx={{ ...textStyle.subtitle }}>
          {props.subtitle.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} container sx={{ marginTop: "40px" }}>
        <TextField
          sx={{
            width: "100%",
            color: "#fff",
            "& .MuiInputBase-root": {
              color: "#fff"
            }
          }}
          multiline
          minRows={3}
          placeholder="Ketik saran anda"
          value={props.data[props.dataKey]}
          onChange={e => {
            props.setData({ ...props.data, [props.dataKey]: e.target.value })
            // if (!!e.target.value) {
            //   if (!isTextareaFilled) {
            //     setTextareaFilled(true)
            //     props.nextStep()
            //   }
            // } else {
            //   if (isTextareaFilled) {
            //     setTextareaFilled(false)
            //     props.prevStep()
            //   }
            // }
          }}
        />
      </Grid>
    </Grid>
  );
}

export default App;
