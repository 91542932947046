import Button from "@mui/material/Button";
import {FormControlLabel, LinearProgress, RadioGroup} from "@mui/material";
import Grid from '@mui/material/Grid';
import {useNavigate} from "react-router-dom";
import {Component, useState} from "react";
import Typography from "@mui/material/Typography";
import * as PropTypes from "prop-types";
import Rating from '@mui/material/Rating';
import CircleIcon from '@mui/icons-material/Circle';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import FormControl from "@mui/material/FormControl";
import Radio from '@mui/material/Radio';
import textStyle from "../../util/textStyle";

function App(props) {
  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={props.image}
          className="illustration"
          alt="logo"
          style={{ height: "10vh", margin: "20px 0px 40px 0px" }}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Typography gutterBottom sx={{ ...textStyle.title }}>
          {props.title.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Typography gutterBottom sx={{ ...textStyle.subtitle }}>
          {props.subtitle.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} container sx={{ marginTop: "40px" }}>
        <Grid item xs={"auto"}>
          <Typography gutterBottom sx={{ fontSize: "12px", color: "#bbb", fontFamily: "Montserrat" }}>
            {props.scale.low.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs />
        <Grid item xs={"auto"}>
          <Typography gutterBottom sx={{ fontSize: "12px", color: "#bbb", fontFamily: "Montserrat" }}>
            {props.scale.high.toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs />
        <Grid item xs={"auto"}>
          <FormControl>
            <RadioGroup
              row
              sx={{ "& .MuiRadio-root": { color: "#fff" } }}
              value={props.data[props.dataKey]}
              onChange={e => {
                props.setData({ ...props.data, [props.dataKey]: parseInt(e.target.value) })
                props.nextStep()
              }}
            >
              {
                [1, 2, 3, 4, 5].map((val, i) =>
                  <FormControlLabel
                    key={i}
                    value={val}
                    control={
                      <Radio
                        checkedIcon={<CircleIcon />}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 40,
                          },
                        }}
                      />
                    }
                    label={val}
                    labelPlacement={"bottom"}
                    sx={{ margin: "0px 5px" }}
                  />
                )
              }
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs />
      </Grid>
    </Grid>
  );
}

export default App;
