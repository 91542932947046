import logo from '../logo.svg';
import LoadingButton from '@mui/lab/LoadingButton';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import apiConfig from "../util/apiConfig";
import { red, grey } from '@mui/material/colors';

function App() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [ isBranchImpressive, setBranchImpressive ] = useState(true)
  const [ isLoading, setLoading ] = useState(true)
  const [ isError, setError ] = useState(false)

  useEffect(() => {
    axios.post(
      `${apiConfig.base_url}v1/survey/uid`,
      { uid: id }
    )
      .then(response => {
        console.log(response)
        if (response?.data?.survey.length === 0) {
          setBranchImpressive(response?.data?.branches?.survey_impressive)
          setLoading(false)
        } else {
          navigate("/success")
        }
      })
      .catch(e => setError(true))
  }, [])

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px"
      }}
    >
      <img src={"/Logo.png"} className="App-logo" alt="logo" style={{ width: "70%" }} />
      <p
        style={{
          color: "#fff",
          fontSize: "16px",
          fontWeight: 800,
          textAlign: "center",
          letterSpacing: "0.5px",
          marginTop: "50px"
        }}
      >
        TELL US ABOUT YOUR EXPERIENCE!
      </p>
      <div
        style={{
          width: "70%",
          height: "1px",
          backgroundColor: "#fff",
          margin: "10px 0px 20px 0px"
        }}
      />
      <p
        style={{
          textAlign: "center",
          fontWeight: 400,
          fontSize: "15px",
          color: "#eee",
          letterSpacing: "0.5px",
          lineHeight: "25px",
          marginBottom: "50px"
        }}
      >
        We’re thrilled you’ve just experienced
        Pandora! Now, we want to hear from you.
        Help us improve and create even more
        thrilling adventures by answering a few
        quick questions.
      </p>
      <LoadingButton
        loading={isLoading && !isError}
        disabled={isError}
        variant="contained"
        style={{
          width: "100%",
          padding: "15px",
          fontWeight: 900,
          fontSize: "15px",
          fontFamily: "Montserrat",
          letterSpacing: "1.5px",
          backgroundColor: red[900],

          "& .Mui-disabled": {
            backgroundColor: "#fff !important"
          }
        }}
        onClick={() => navigate("/q", { state: { id, isBranchImpressive } })}
      >
        <b>
          { isError ? "Something went wrong" : "Start Questionnaire" }
        </b>
      </LoadingButton>
    </div>
  );
}

export default App;
